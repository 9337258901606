/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25/04/2022.
 */
import React from 'react'

import FadeUp from '../animation/FadeUp'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type Props = {}

export const SignupSteps = ({}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid section-margin" style={{ background: '#FFFFFF' }}>
      <div className="container-fluid content-container pt-4 ">
        <div className="row ">
          <FadeUp className="col-12 mt-6">
            {/* How to participate */}
            <h4 className="text-center mb-6">{t('questionBold3')}</h4>
          </FadeUp>

          <FadeUp className="col-12 col-md-4 mb-6 noselect d-flex justify-content-center">
            <div className="px-6">
              <StaticImage
                placeholder={'none'}
                loading="lazy"
                src={'../images/sponsor.png'}
                alt="sponsors"
              ></StaticImage>
              {/* Sign up online */}
              <h6 className="font-weight-bold my-4">{t('imageTextHeadline1')}</h6>
              {/* Sign up with your name, email and ZIP code */}
              <p className=" mb-5 ">{t('imageTextSubHeadline1')}</p>
            </div>
          </FadeUp>

          <FadeUp delay={0.3} className="col-12 col-md-4 mb-6 noselect d-flex justify-content-center">
            <div className="px-6">
              <StaticImage
                placeholder={'none'}
                loading="lazy"
                src={'../images/physician.png'}
                alt="physician"
              ></StaticImage>
              {/* Answer questions online */}
              <h6 className="font-weight-bold my-4">{t('imageTextHeadline2')}</h6>
              {/* Answer some questions about your condition, so our study personnel can evaluate if you are a match for the study */}
              <p className=" mb-5">{t('imageTextSubHeadline2')}</p>
            </div>
          </FadeUp>

          <FadeUp delay={0.4} className="col-12 col-md-4 mb-6 noselect d-flex justify-content-center">
            <div className="px-6">
              <StaticImage
                placeholder={'none'}
                loading="lazy"
                src={'../images/patients.png'}
                alt="patients"
              ></StaticImage>
              {/* Visit a clinic */}
              <h6 className="font-weight-bold my-4">{t('imageTextHeadline3')}</h6>
              {/* If you are a match for the study you will be invited to a clinic for more information before you decide if you want to participate */}
              <p className="pr-md-7 mb-5">{t('imageTextSubHeadline3')}</p>
            </div>
          </FadeUp>
        </div>
      </div>
    </div>
  )
}
