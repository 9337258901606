/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import React from 'react'

//import CheckMark from '../assets/check-mark.svg'
import CheckMark from '../assets/check-mark.svg'

import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const WhatsInItForYou = () => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid">
      <div className="container el-margin">
        <div className="row" style={{}}>
          <div className="col-12 col-md-6 mx-auto text-center">
            {/* Whats In It For You */}
            <h2>{t('questionBold1')}</h2>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 col-md-4 text-center p-4">
            <div className="p-4">
              <CheckMark></CheckMark>
            </div>
            {/* You will be part of testing a new possible treatment for osteoarthritis */}
            <h5>{t('imageText1')}</h5>
          </div>
          <div className="col-12 col-md-4 text-center p-4">
            <div className="p-4">
              <CheckMark></CheckMark>
            </div>
            {/* You will be guided through the study by health care professionals */}
            <h5>{t('imageText2')}</h5>
          </div>
          <div className="col-12 col-md-4 text-center p-4">
            <div className="p-4">
              <CheckMark></CheckMark>
            </div>
            {/* Your knees will be thoroughly examined */}
            <h5>{t('imageText3')}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}
