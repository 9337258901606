/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/04/2021.
 */
import React, { useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

import { useForm } from 'react-hook-form'
import Cross from '../../assets/cross.svg'
import './bottom-drawer-sheet.scss'
import { SignUpForm } from '../signUpForm/SignUpForm'

import { useTranslation } from 'gatsby-plugin-react-i18next'

const zIndex = { zIndex: 10 }

export const BottomDrawerSheet = ({ openDrawer, closeFunc = true }) => {
  const [state, setState] = useState('')

  const onDismiss = () => {
    closeFunc(false)
  }

  const { t } = useTranslation()

  const onClosed = (evt) => {
    //console.log(' BottomDrawerSheet > onClosed = ', evt)
  }

  const formHook = useForm()
  const { handleSubmit } = formHook

  return (
    <BottomSheet
      blocking={false}
      onSpringEnd={onClosed}
      open={openDrawer}
      onDismiss={onDismiss}
      //header={header}

      snapPoints={(obj) => {
        //console.log(' BottomDrawerSheet > obj = ', obj)

        return obj.maxHeight
        /*const factor = 0.8
        return obj.minHeight > obj.maxHeight * factor ? obj.maxHeight * factor : obj.minHeight*/
      }}
      className="position-relative "
      style={zIndex}
    >
      <div className="container-fluid position-relative">
        <button onClick={onDismiss} className="position-absolute btn" style={{ top: 0, right: 15 }}>
          <Cross style={{ width: 20, height: 20 }} />
        </button>

        <div className="row" style={{}}>
          <div className="col-12 mt-2 mb-5 text-black ">
            <h6 className="mb-2 text-black ">{t('formHeadline')}</h6>
            <p className="mb-3">{t('formSubHeadline')}</p>
            <SignUpForm hideBtn={false} handleSubmit={handleSubmit} formHook={formHook}></SignUpForm>
          </div>
        </div>
      </div>
    </BottomSheet>
  )
}
