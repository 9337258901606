/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './image-header.scss'
import { SignUpForm } from '../signUpForm/SignUpForm'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useForm } from 'react-hook-form'

const ImageHeader = ({ openDrawer }) => {
  const { t } = useTranslation()

  //
  // we are using two different formhooks for mobile and desktop
  //
  const formHook = useForm()
  const { handleSubmit } = formHook

  return (
    <div className="d-flex position-relative image-header ">
      <div className="container-fluid position-absolute h-100 " style={{ zIndex: -10 }}>
        <div className="row h-100" style={{ background: '#03090c' }}>
          <div className="col-12 h-100 px-0 mx-auto " style={{ maxWidth: 2000 }}>
            <StaticImage
              className="h-100 w-100 "
              objectFit="cover"
              src="../../images/header_003.jpg"
              objectPosition="50% 50%"
              alt="studies and me"
              quality={100}
            ></StaticImage>
          </div>
        </div>
      </div>

      <div className="container-fluid content-container h-100">
        <div className="row h-100" style={{}}>
          <div className="col-12 col-md-8 col-lg-7 col-xl-7 h-100 d-flex align-items-center">
            <div className="px-3 ps-md-5 header-text">
              {/* <h6 className="text-center text-md-start text-white mb-2 ">{t('header.headByline')}</h6> */}
              {/* Make a difference for people living with osteoarthritis */}
              <h2 className="text-center text-md-start text-white mb-5">{t('header.headline')}</h2>
              {/* Contribute to new knowledge about future treatment of osteoarthritis by participating in a clinical study */}
              <p className="text-white text-center text-md-start">{t('header.body')}</p>
              <div className="mt-5 d-flex flex-md-row flex-column align-content-center">
                <button
                  onClick={openDrawer}
                  className="d-block d-sm-none btn btn-primary rounded-pill px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('signupButton')}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-5 d-none d-md-flex flex-column align-items-end justify-content-center py-5">
            {/*<div className="bg-white  d-flex flex-column align-items-start">dadasda sdd</div>*/}
            <div className="bg-white rounded shadow-sm px-4 py-4 " style={{ maxWidth: 400 }}>
              <h5 className="mb-2 mt-1 fw-bold">{t('formHeadline')}</h5>
              <p className="mb-3">{t('formSubHeadline')}</p>

              <SignUpForm formHook={formHook} handleSubmit={handleSubmit}></SignUpForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageHeader
