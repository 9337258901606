import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import ImageHeader from '../components/header/ImageHeader'
import { SectionTitle } from '../components/SectionTitle'
import { SignupSteps } from '../components/SignupSteps'

import { BottomDrawerSheet } from '../components/drawer/BottomDrawerSheet'

import { dataStore } from '../store/dataStore'
import URLSearchParams from '@ungap/url-search-params'
import { setUtm } from '../utils/utm'
import { WhatsInItForYou } from '../components/WhatsInItForYou'
import { FactsAboutTheStudy } from '../components/FactsAboutTheStudy'
import { ConsentModal } from '../components/consent/ConsentModal'

const IndexPage = (props) => {
  // should give the same output as the useTranslation hook
  // const language = props.data.locales.edges[0].node.language

  const location = props.location
  //const { t, i18n } = useTranslation()

  const hasQuery = location.search
  setUtm(hasQuery)

  const getLocation = dataStore((state) => state.getLocation)
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    const queryString = new URLSearchParams(location.search)

    const testIp = queryString.get('testip') || ''
    console.log(' index > ++ testIp = ', testIp)
    getLocation(testIp)
  }, [getLocation, location.search])

  const openDrawer = () => {
    console.log(' index > open = ')
    setShowDrawer(true)
  }

  const closeDrawer = (value) => {
    console.log(' index > open = ')
    setShowDrawer(value)
  }

  return (
    <Layout location={location} title={'asdasd'}>
      <BottomDrawerSheet openDrawer={showDrawer} closeFunc={closeDrawer}></BottomDrawerSheet>
      <Seo title="Want to participate?" />
      <ImageHeader openDrawer={openDrawer}></ImageHeader>

      <div className="container-fluid py-7" style={{ background: '#FEF7F3' }}>
        <div className="container-fluid content-container  my-4">
          <div className="row ">
            <SectionTitle title="About the study"></SectionTitle>
            <div className="col-12 col-md-6 mb-4">
              <h3>
                Setting new standards in
                <br />
                clinical research
              </h3>
            </div>

            <div className="col-12 col-md-6  ">
              <ConsentModal></ConsentModal>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam aliquid, animi dicta dolore
                dolores enim facilis harum ipsa itaque iure labore natus necessitatibus nobis non nulla odio perferendis
                porro possimus reprehenderit, sunt similique sint totam vero. Exercitationem fugiat sapiente vel.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WhatsInItForYou></WhatsInItForYou>
      <FactsAboutTheStudy></FactsAboutTheStudy>
      {/*<HowToParticipate></HowToParticipate>*/}
      <SignupSteps></SignupSteps>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
