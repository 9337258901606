/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/10/2022.
 */
import React, { useRef, useState } from 'react'
import { Dialog, DialogOverlay, DialogContent } from '@reach/dialog'
import ConsentIcon from '../../assets/consent.svg'

// include styles
import './consent-modal.scss'
import { win } from '../../utils/browserMock'
import { useWindowSize } from '../../hooks/useWindowResize'

type Props = {}

export const ConsentModal = ({}: Props) => {
  const [show, setShow] = useState<boolean>(false)

  const contentRef = useRef()
  /*const w = Math.min(win.innerWidth, 650)
  const h = Math.min(win.innerHeight, 700)*/

  const winRect = useWindowSize()
  /*const w = winRect.width //Math.max(winRect.width, 700)
  const h = Math.min(winRect.height, 700)*/

  const showModal = () => {
    setShow(true)
  }

  const hideModal = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    setShow(false)
  }

  const approveClick = () => {
    hideModal()
  }
  const STUDY_NAME = 'STRIDES'

  const footerHeight = 80

  return (
    <div className="position-relative">
      <button onClick={showModal}>Show Dialog</button>

      <Dialog isOpen={show} className="position-relative" aria-label="consent">
        <button
          className="position-absolute btn-close btn "
          role="button"
          tabIndex={1}
          onKeyPress={hideModal}
          style={{ top: 20, right: 20, zIndex: 110 }}
          onClick={hideModal}
        ></button>

        <div className="container-fluid px-0 h-100" style={{}}>
          <div className="h-100 d-flex flex-column">
            <div className=""></div>

            <div className="px-5" style={{ overflowY: 'scroll' }}>
              <div className="">
                <div className="my-5 d-flex justify-content-center">
                  <ConsentIcon className="mt-5"></ConsentIcon>
                </div>

                <h3 className="text-center ">
                  Your privacy
                  <br />
                  is our priority
                </h3>
                <p className="mt-5 text-center ">
                  Do you consent to Studies&Me's processing of personal and sensitive information about you, with the
                  purpose of assessing whether you may be eligible to participate in the{' '}
                  <span className="fw-bold">{STUDY_NAME}</span> study?
                </p>

                <p className="mt-5 ">
                  Studies&Me will not share any of your sensitive personal information with any third parties, without
                  your explicit consent.{' '}
                </p>

                <p className="mt-4 p-small">
                  The purpose of this form is to obtain your explicit and prior consent to the processing of the
                  sensitive personal information you provide in the following questionnaire for the {STUDY_NAME} study.
                </p>

                <p className="mt-3  p-small">
                  We will collect and process your full name, email address and phone number, as well as sensitive
                  personal information about your health, including your age, height, weight and medical history.
                </p>
                <p className="mt-3  p-small">
                  The purpose of processing such personal information about you is to assess whether you may be eligible
                  to participate in the <span className="fw-bold">{STUDY_NAME}</span> study. If we assess that you may
                  be eligible, we will disclose your name, telephone number and email address to the nearest study
                  clinic that will contact you with regards to the next phases of the study.
                </p>

                <p className="mt-3  p-small">
                  Please note that, depending on your jurisdiction, you may have data subject rights, including the
                  right to withdraw your consent at any time by sending an e-mail to privacy@studiesandme.com. You can
                  read more about your other data subject rights in our privacy policy.If you have any questions
                  regarding the processing of your personal information, please contact Studies&Me on
                  <a href="mailto:privacy@studiesandme.com" className="ms-1 text-black">
                    privacy@studiesandme.com.
                  </a>
                </p>
                <p className="mt-4 mb-5  p-small">
                  Studies&Me A/S, business registration no.: 41600020, Hauser Pl. 20, 1. floor, DK-1127, Copenhagen K
                  ("Studies&Me") collects and uses (processes) your personal information for the purposes of clinical
                  study recruitment and pre-screening.
                </p>
              </div>
            </div>
            {/* ---- FOOTER --------- */}
            <div ref={contentRef} className="pt-2 px-2 border-top ios-bottom ">
              <button
                ref={contentRef}
                role="button"
                tabIndex={0}
                onKeyPress={approveClick}
                onClick={approveClick}
                className="btn btn-primary w-100 py-1"
              >
                <p className="text-white py-2 ">Accept and continue</p>
              </button>
            </div>
          </div>
        </div>

        {/*<p>It is your job to close this with state when the user clicks outside or presses escape.</p>
        <button onClick={hideModal}>Okay</button>*/}
      </Dialog>
    </div>
  )
}

const Button = ({ onClick, children }) => {
  return (
    <button onClick={onClick} className="btn btn-primary w-100 py-1">
      {children}
    </button>
  )
}

const DeclineButton = ({ onClick, children }) => {
  return (
    <button onClick={onClick} className="btn btn-secondary w-100 mt-3 py-3">
      {children}
    </button>
  )
}

/*

<div className="position-relative">
      <button onClick={showModal}>show</button>

      <Rodal
        width={w}
        height={h}
        showMask={true}
        duration={200}
        closeOnEsc
        visible={show}
        onClose={hideModal}
        animation="zoom"
      >
        <div
          className="position-absolute w-100 noclickable"
          style={{
            bottom: 15,
            left: 0,
            zIndex: 99,
            height: 200,
            //background: 'red',
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) ',
          }}
        ></div>
        <div
          className="position-absolute w-100 d-flex px-2 px-md-6"
          style={{
            bottom: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <div className="py-4 w-25 px-1">
            <DeclineButton onClick={approveClick}>
              <p className="text-white ">Decline</p>
            </DeclineButton>
          </div>
          <div className="py-4 w-75 px-1">
            <Button onClick={approveClick}>
              <p className="text-white">Accept and continue</p>
            </Button>
          </div>
        </div>
        <div className="h-100 pb-6 px-3 px-md-6 pt-6 position-relative" style={{ overflowY: 'scroll' }}>
          <h4>YOUR PRIVACY IS OUR PRIORITY</h4>
          <p className="lead mb-5 mt-5">
            Do you consent to Studies&Me's processing of personal and sensitive information about you, with the purpose
            of assessing whether you may be eligible to participate in the {STUDY_NAME} study?
          </p>

          <p className="lead mb-6 fs-6 ">
            Studies&Me will not share any of your sensitive personal information with any third parties, without your
            explicit consent.{' '}
          </p>

          <p className="mt-4">
            The purpose of this form is to obtain your explicit and prior consent to the processing of the sensitive
            personal information you provide in the following questionnaire for the {STUDY_NAME} study.
          </p>

          <p className="mt-4">
            We will collect and process your full name, email address and phone number, as well as sensitive personal
            information about your health, including your age, height, weight and medical history.
          </p>
          <p className="mt-4">
            The purpose of processing such personal information about you is to assess whether you may be eligible to
            participate in the [insert the name of the specific study] study. If we assess that you may be eligible, we
            will disclose your name, telephone number and email address to the nearest study clinic that will contact
            you with regards to the next phases of the study.
          </p>

          <p className="mt-4">
            Please note that, depending on your jurisdiction, you may have data subject rights, including the right to
            withdraw your consent at any time by sending an e-mail to privacy@studiesandme.com. You can read more about
            your other data subject rights in our privacy policy.If you have any questions regarding the processing of
            your personal information, please contact Studies&Me on privacy@studiesandme.com.
          </p>
          <p className="mt-4">
            Studies&Me A/S, business registration no.: 41600020, Hauser Pl. 20, 1. floor, DK-1127, Copenhagen K
            ("Studies&Me") collects and uses (processes) your personal information for the purposes of clinical study
            recruitment and pre-screening.
          </p>
        </div>
      </Rodal>
    </div>


 */
