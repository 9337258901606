import React, { useEffect, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { dataStore } from '../../store/dataStore'
import { ComboBox } from '../combobox/ComboBox'
import './sign-up-form.scss'
import { CheckBox } from './CheckBox'
import { signUpUser } from '../../service/SignUpUser'

type Props = {
  hideBtn: boolean
  handleSubmit: (onError) => void
  formHook: any
}

const BORDER_COLOR = '#b6b6b6'

export const SignUpForm = ({ hideBtn, formHook, handleSubmit }: Props) => {
  const { t, i18n } = useTranslation()

  const {
    register,
    setValue,
    formState: { errors },
  } = formHook

  const userLocation = dataStore((state) => state.userLocation)
  const validCountry = dataStore((state) => state.validCountry)

  const onSubmit = (data, e) => {
    e.preventDefault()
    signUpUser(data, i18n.language).catch((error) => {
      console.log('error = ', error)
      //return Promise.reject(error)
    })
  }

  const onError = (err, e) => {
    console.log(' SignUpForm > err = ', err)
    console.log(' SignUpForm > e = ', e)
    e.preventDefault()
  }

  useEffect(() => {
    if (userLocation) {
      register('position')
      setValue('position', userLocation)
    }
  }, [userLocation])

  return (
    <div className="signup-form container-fluid px-0">
      <form>
        <div className="row" style={{}}>
          {/*<div className="col-12">
            <p className="p-mini mb-1 text-end text-decoration-underline">Why we need your email</p>

          </div>*/}

          <div className="col-12 position-relative mb-2">
            <InputNameField
              formHook={formHook}
              formKey={'name'}
              icon={null}
              // Your name
              label={t('fieldName')}
            />
          </div>

          <div className="col-12 position-relative">
            <InputEmailField
              formHook={formHook}
              formKey={'email'}
              icon={null}
              // Your email
              label={t('fieldEmail')}
            />
          </div>
        </div>

        <div className="row" style={{}}>
          {/*<div className="col-12 mt-1">
            <p className="p-small p-mini mb-1 text-end text-decoration-underline">Why we need your zipcode</p>
          </div>*/}

          <div className="col-12 mt-2">{/*FILLER*/} </div>

          <ComboBox formHook={formHook}></ComboBox>

          {/*{userLocation !== null && validCountry === false ? (
            <div className="col-12 mt-1 ">
              <div className="px-3 py-2" style={{ background: '#eaeaea' }}>
                <p className="fw-bold p-small text-black">Please notice</p>
                <p className="p-small p-small ">
                  The biosplice study is only running in the US, to enter you must have a valid US zipcode
                </p>
              </div>
            </div>
          ) : null}*/}
        </div>

        <CheckBox formHook={formHook} formKey={'acceptTerms'}></CheckBox>

        {hideBtn ? null : (
          <button onClick={handleSubmit(onSubmit, onError)} className="btn btn-primary w-100 mt-3 py-3">
            {/* Sign up now */}
            {t('signupButton')}
          </button>
        )}
      </form>
    </div>
  )
}

const InputEmailField = ({ formHook, formKey = 'test', label, icon }) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = formHook
  const { t } = useTranslation()
  const errorState = errors[formKey]
  const borderState = errorState ? 'red' : BORDER_COLOR

  return (
    <div className="">
      <div
        className="form-floating"
        style={{
          border: `1px solid ${borderState}`,
          borderRadius: 4,
        }}
      >
        <input
          id="floatingInput"
          className=" w-100 form-control "
          placeholder="dasdasd"
          style={{ border: 'none', outline: 'none !important', boxShadow: '0 0 0px #719ECE', fontSize: '1rem' }}
          {...register(formKey, {
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              // The entered email address is not in a valid format
              message: t('validEmail'),
            },
            // We need your email to be able to contact you with details for the study
            required: t('requiredEmail'),
          })}
        />

        <label htmlFor="floatingInput" style={{ color: '#8a8a8a', fontSize: '1rem' }}>
          {t('fieldEmail')}
        </label>
      </div>
      {errorState ? <div className="p-small text-danger mt-1">{errorState.message}</div> : null}
    </div>
  )
}

const InputNameField = ({ formHook, formKey = 'test', label, icon }) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = formHook
  const { t } = useTranslation()
  const errorState = errors[formKey]
  const borderState = errorState ? 'red' : BORDER_COLOR

  return (
    <div className="">
      <div
        className="form-floating"
        style={{
          border: `1px solid ${borderState}`,
          borderRadius: 4,
        }}
      >
        <input
          id="floatingInput"
          className=" w-100 form-control "
          placeholder="Your name"
          style={{ border: 'none', outline: 'none !important', boxShadow: '0 0 0px #719ECE', fontSize: '1rem' }}
          {...register(formKey, {
            // Please enter a minimum of 2 charaters
            validate: (value) => value.length > 3 || t('nameMinimum'),
            // Please enter your name
            required: t('nameRequired'),
          })}
        />

        <label htmlFor="floatingInput" style={{ color: '#8a8a8a', fontSize: '1rem' }}>
          {t('fieldName')}
        </label>
      </div>
      {errorState ? <div className="p-small text-danger mt-1">{errorState.message}</div> : null}
    </div>
  )
}
