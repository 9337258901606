/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/09/2022.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const FactsAboutTheStudy = () => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid el-margin" style={{ background: '#F3FAF5' }}>
      <div className="container py-6 ">
        <div className="row" style={{}}>
          <div className="col-12 col-md-6 mx-auto text-center">
            {/* <h6>Facts about the study</h6> */}
            {/* What you need to know */}
            <h3>{t('questionBold2')}</h3>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 col-md-4 text-center py-4 px-5 ">
            <StaticImage
              placeholder={'none'}
              loading="lazy"
              src={'../images/physician.png'}
              alt="physician"
            ></StaticImage>
            {/* We are looking for participants between the age of 40-80 to join a 16 week clinical study  */}
            <h5 className="mt-5 px-4">{t('imageText4')}</h5>
          </div>
          <div className="col-12 col-md-4 text-center p-4 ">
            <StaticImage
              placeholder={'none'}
              loading="lazy"
              src={'../images/physician.png'}
              alt="physician"
            ></StaticImage>
            {/* The study will evaluate a new possible osteoarthritis treatment that is being developed to potentially reduce knee pain */}
            <h5 className="mt-5 px-4">{t('imageText5')}</h5>
          </div>
          <div className="col-12 col-md-4 text-center p-4">
            <StaticImage
              placeholder={'none'}
              loading="lazy"
              src={'../images/physician.png'}
              alt="physician"
            ></StaticImage>
            {/* The treatment is administered by one injection into the affected knee */}
            <h5 className="mt-5 px-4">{t('imageText6')}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}
